@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "chubbo";
  src: url(./assets/font/Chubbo_Complete/Fonts/OTF/Chubbo-Bold.otf);
}
@font-face {
  font-family: "supreme";
  src: url(./assets/font/Supreme_Complete/Fonts/WEB/fonts/Supreme-Bold.ttf);
}
@font-face {
  font-family: "supreme-100";
  src: url(./assets/font/Supreme_Complete/Fonts/WEB/fonts/Supreme-Light.ttf);
}

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.catalog{
  background: linear-gradient(180deg, #273B9D 0%, #D67195 100%);
}

.btn {
  position: relative;
  overflow: hidden;
}

.btn::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color: #1d4ed8;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.4s ease-in-out;
  z-index: -1;
}

.btn:hover::before {
  transform: translate(-50%, -50%) scale(1);
}


.slick-dots li button:before {
  opacity: 0 !important;
  color: black;
  margin-top: 10px !important;

}
.slick-dots li.slick-active button:before {
  opacity: .75 !important;
  color: black;
  
}

.slick-dots li button::before {
  background-color: black !important;
  border-radius: 50% !important;
  width: 12px !important;
  height: 12px !important;
  
}
.slick-dots button{
  width: 12px !important;
  height: 12px !important;
  background-color: #000;
  background-color: inherit !important;
  border-radius: 50% !important;
  padding: 5px !important;
  border: 1px solid rgb(151, 146, 146) !important;
  margin-top: 10px !important;
}

.slick-track{
  height: 620px;
}

.marquee-content {
  animation: marquee 20s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(calc(-50px*7));
  }
}

@media screen and (max-width:768px) {
  .slick-dots li button:before,
  .slick-dots button {
    margin-top: unset !important;
  
  }
  .marquee-content {
    animation: marquee 10s linear infinite;
  }
}
